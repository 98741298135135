'use client';

import React from 'react';
import { useAuth, useOrganizationList } from '@clerk/nextjs';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import Navigation from '../Navigation';
import useNavigation from '../../hooks/useNavigation';
import NoOrgsModal from '../modals/NoOrgsModal';
import useMixpanel from '../../hooks/useMixpanel';

type Props = {
  children: React.ReactNode;
};

export default function NavWrapper({ children }: Props) {
  const { navigation } = useNavigation();
  const router = useRouter();

  const { sessionId, userId, isSignedIn } = useAuth();
  const { identify } = useMixpanel();
  const [id, setId] = useState<string | null>(null);

  useEffect(() => {
    if (sessionId) {
      setId(userId);
    }
  }, [sessionId, userId]);

  useEffect(() => {
    // This is a workaround for the fact that the session is not available on the first render
    // This also prevents multiple calls to identify
    if (id) {
      identify(id);
    }
  }, [identify, id]);

  // user is not logged in, take them to login page
  if (!isSignedIn) {
    router.push('/login');
    return <></>;
  }

  return (
    <div className="flex w-full flex-col items-center">
      {/* <StatusBanner /> */}
      <Navigation navigation={navigation} />
      <main className="h-full w-full max-w-[150rem] flex-1 py-6">
        <div className="mx-auto">{children}</div>
      </main>
    </div>
  );
}
