import { useOrganization } from '@clerk/clerk-react';

export const useClerkFeatureFlags = () => {
  const { organization } = useOrganization();

  const featureFlagsData = organization?.publicMetadata?.feature_flags as {
    [key: string]: boolean;
  };

  return { featureFlagsData };
};
