import Notification from '../components/notifications/Notification';
import React, { ReactNode, createContext, useContext, useState } from 'react';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
interface NotificationData {
  title: string;
  message: string | JSX.Element;
  type: 'success' | 'error' | 'info';
  timeout?: number;
}

interface NotificationContextValue {
  showNotification: (data: NotificationData) => void;
  hideNotification: () => void;
}

const NotificationContext = createContext<NotificationContextValue>({
  showNotification: () => {},
  hideNotification: () => {},
});

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notificationData, setNotificationData] = useState<NotificationData | null>(null);

  const showNotification = (data: NotificationData) => {
    setNotificationData(data);
  };

  const hideNotification = () => {
    setNotificationData(null);
  };

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      {notificationData && (
        <Notification
          title={notificationData.title}
          message={notificationData.message}
          type={notificationData.type}
          timeout={notificationData.timeout}
          onClose={() => {
            hideNotification();
          }}
        />
      )}
    </NotificationContext.Provider>
  );
};
