import { useEffect, useMemo, useState } from 'react';
import { LinkIcon, HomeIcon, UsersIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import { usePathname } from 'next/navigation';
import { useSession } from '@clerk/nextjs';
import { useClerkFeatureFlags } from './useClerkFeatureFlags';

type navigationItem = {
  name: string;
  href: string;
  icon: any;
  current: boolean;
};

const useNavigation = () => {
  const [currentNavItem, setCurrentNavItem] = useState<string>('');
  const { featureFlagsData: clerkFeatureFlagsData } = useClerkFeatureFlags();

  const { isLoaded } = useSession();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname) {
      setCurrentNavItem(pathname);
    }
  }, [currentNavItem, isLoaded, pathname]);

  const navigation = useMemo(() => {
    if (clerkFeatureFlagsData?.basic_mode) {
      // If basic_mode is active, only show the Dashboard item
      return [{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: currentNavItem.startsWith('/dashboard/') }] as navigationItem[];
    } else {
      return [
        { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: currentNavItem.startsWith('/dashboard/') },
        { name: 'Links', href: '/links', icon: LinkIcon, current: currentNavItem === '/links' },
        clerkFeatureFlagsData?.referrals
          ? { name: 'Campaigns', href: '/campaigns', icon: UsersIcon, current: currentNavItem.startsWith('/campaigns') }
          : undefined,
        {
          name: 'Audiences',
          href: '/audiences',
          icon: UsersIcon,
          current: currentNavItem.startsWith('/audiences'),
        },
        { name: 'Data', href: '/data', icon: CircleStackIcon, current: currentNavItem.startsWith('/data') },
      ].filter((n) => n) as navigationItem[];
    }
  }, [currentNavItem, clerkFeatureFlagsData]);

  return { navigation };
};

export default useNavigation;
