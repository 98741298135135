import { useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { NavigationItem } from '../types';
import Link from 'next/link';
import { classNames } from '../utils/helpers';
import { OrganizationSwitcher, UserButton, useOrganization } from '@clerk/nextjs';
import * as Sentry from '@sentry/nextjs';
import { SpindlNavLogoSmall } from '../../public/icons';

type Props = {
  navigation: NavigationItem[] | null;
};

export default function Navigation({ navigation }: Props) {
  const { organization } = useOrganization();

  useEffect(() => {
    // setting extra context to know which org id has the front-end error for sentry
    if (organization?.publicMetadata?.spindl_org_id) {
      Sentry.setContext('customUserProps', {
        orgId: organization?.publicMetadata?.spindl_org_id,
      });
    }
  }, [organization?.publicMetadata?.spindl_org_id]);

  return (
    <Disclosure as="nav" className="sticky top-0 z-50 w-full bg-white/95 shadow backdrop-blur">
      {({ open }) => (
        <>
          <div className="mx-auto flex h-16 max-w-[150rem] flex-row justify-center px-4 sm:px-6 lg:px-8">
            <div className="flex flex-1 flex-shrink-0 flex-grow items-center justify-start fill-black">
              <Link legacyBehavior href={'/'}>
                <a>
                  <SpindlNavLogoSmall />
                </a>
              </Link>
            </div>
            <div className="hidden flex-1 justify-center sm:flex sm:gap-4 lg:gap-8">
              {navigation &&
                navigation.map(
                  (item: NavigationItem, index: number) =>
                    item && (
                      <Link legacyBehavior href={item.href} key={item.name + index}>
                        <a
                          className={classNames(
                            item.current
                              ? 'text-brand-navy-dark hover:text-brand-text-base border-brand-green-base'
                              : 'text-brand-gray-base hover:text-brand-text-hover border-transparent',
                            'inline-flex items-center border-b-2 px-2 pt-1 text-sm font-medium',
                          )}>
                          {item.name}
                        </a>
                      </Link>
                    ),
                )}
            </div>
            <div className="hidden flex-1 flex-grow gap-4 sm:flex sm:items-center sm:justify-end">
              <UserButton afterSignOutUrl="/login" />
              <OrganizationSwitcher hidePersonal afterSelectOrganizationUrl="/dashboard" />
              <hr className="h-6 self-center border-l border-gray-300" />
              <Link legacyBehavior href={'/settings'}>
                <button
                  type="button"
                  className="focus:ring-brand-green-base rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </Link>
            </div>

            <div className="-mr-2 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open main menu</span>
                {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
              </Disclosure.Button>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
