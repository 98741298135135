'use client';

import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { mainnet, polygon, optimism, arbitrum, goerli, arbitrumGoerli, sepolia, bsc, bscTestnet, optimismGoerli } from 'wagmi/chains';
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import mixpanel from 'mixpanel-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/nextjs';
import { NotificationProvider } from '../context/NotificationContext';
import React, { Suspense } from 'react';
import NavWrapper from '../components/layout/NavWrapper';
import { usePathname } from 'next/navigation';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN : 'some token');

const { chains, provider, webSocketProvider } = configureChains(
  [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    bsc,

    // testnets
    goerli,
    arbitrumGoerli,
    optimismGoerli,
    bscTestnet,
    sepolia,
  ],
  process.env.NEXT_PUBLIC_ALCHEMY_API
    ? [
        alchemyProvider({
          apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API as string,
        }),
        publicProvider(),
      ]
    : [publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: 'Admin Spindl On Chain',
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
  chains,
});

const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
  webSocketProvider,
});

export default function Providers({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const isAdminPage = pathname.startsWith('/admin');

  return (
    <>
      <Suspense fallback={<></>}>
        <NotificationProvider>
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
              <QueryClientProvider client={queryClient}>
                <ClerkProvider>
                  <SignedIn>{isAdminPage ? children : <NavWrapper>{children}</NavWrapper>}</SignedIn>
                  <SignedOut>{children}</SignedOut>
                </ClerkProvider>
              </QueryClientProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </NotificationProvider>
      </Suspense>
    </>
  );
}
