import { useAuth, useSession, useUser } from '@clerk/nextjs';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

const useMixpanel = () => {
  const [url, setUrl] = useState<string | null>(null);

  const { session: data } = useSession();
  const { orgId } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    // Only track page view if we have the org and user
    if (orgId && data && url) {
      mixpanel.track('Page View', {
        page: url,
        user: data.id,
        org: orgId,
      });
      // Reset the url so that we don't track the same page view twice
      setUrl(null);
    }
  }, [orgId, data, url]);

  // This function is called from the page components to track page views
  const pageView = (page: string) => {
    setUrl(page);
  };

  const identify = (id: string) => {
    mixpanel.identify(id);
    mixpanel.people.set({
      name: user?.fullName,
      email: user?.emailAddresses[0].emailAddress,
    });
  };

  return {
    pageView,
    identify,
  };
};

export default useMixpanel;
